import FetchProjects from "../../../services/project/FetchProjects";
import ProjectActionCreator from "../../actions/project/ProjectActionCreator";

function fetchAll(action) {
    return async function fetchAllThunk(dispatch, getState) {
        await FetchProjects()
            .then(response => {
                dispatch(ProjectActionCreator.fetchProjectsFinished(response.data.message.projects));
            })
            .catch(error => {
                dispatch(ProjectActionCreator.fetchProjectError(error));
            });
    }
}

function fetchAllFinished(state, action) {
    return {
        ...state,
        elements: action.payload.projects
    }
}

export default {
    fetchAll,
    fetchAllFinished
}

