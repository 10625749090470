import SignUp from "../../../services/user/SignUp";
import UserActionCreator from "../../actions/user/UserActionCreator";
import UserSignInReducer from "./UserSignInReducer";

function signUp(action) {
    return async function signUpThunk(dispatch, useState) {
        await SignUp(
            action.payload.login,
            action.payload.email,
            action.payload.password,
            action.payload.projectId
        )
            .then(response => {
                dispatch(
                    UserSignInReducer.signIn(
                        UserActionCreator.signIn(
                            action.payload.login,
                            action.payload.password
                        )
                    )
                );
            }).catch(
                error => {
                    dispatch(UserActionCreator.signUpError(error));
                }
            );
    }
}

export default {
    signUp
}
