
import Types from '../../../utils/dictionaries/action/Types'
import UserMeReducer from "./UserMeReducer";
import UserSignInReducer from "./UserSignInReducer";

export default function UserReducer(state = [], action) {
    switch (action.type) {
        case Types.USER.ME.FETCH.FINISHED:
            return UserMeReducer.fetchMeFinished(state, action);
        case Types.USER.SIGNIN.FINISHED:
            return UserSignInReducer.signInFinished(state, action);
        default:
            break;
    }

    return state;
}
