// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.content {
    min-height: calc(100vh - 120px);
    width: 100% !important;
    max-width: 100% !important;
}

.skeletonLine {
    margin-top: 5px;
    margin-bottom: 5px;
}

.signInFormControl {
    margin-top: 10px;
    margin-bottom: 10px;
}

.signUpFormControl {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 95%;
}

.paragraph {
    margin-bottom: 10px;
}

.centredBox {
    padding: 30px 90px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI,+BAA+B;IAC/B,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.content {\n    min-height: calc(100vh - 120px);\n    width: 100% !important;\n    max-width: 100% !important;\n}\n\n.skeletonLine {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.signInFormControl {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.signUpFormControl {\n    margin-top: 10px;\n    margin-bottom: 10px;\n    max-width: 95%;\n}\n\n.paragraph {\n    margin-bottom: 10px;\n}\n\n.centredBox {\n    padding: 30px 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
