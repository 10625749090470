import Header from "../components/Header";
import {Box, Button, Center, Container, Divider, Text} from "@chakra-ui/react";
import {Outlet} from "react-router";
import Footer from "../components/Footer";
import SignInFormComponent from "../components/user/signin/SignInFormComponent";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import PageContent from "../components/PageContent";
import Session from "../utils/dictionaries/session";

export default function IndexLayout(props) {
    const generalData = props.generalData;
    const user = useSelector(state => state.user.me);

    const [searchParams, setSearchParams] = useSearchParams();

    let shouldLogout = searchParams.get('logout');

    let authUrlParam = '';

    let redirectAuthUrl = searchParams.get('redirectAuthUrl');

    if (redirectAuthUrl !== null && redirectAuthUrl.length > 0) {
        authUrlParam = '?redirectAuthUrl=' + redirectAuthUrl;
    }

    if (shouldLogout === 'true') {
        logOutAndRedirectBack();
    }

    if (user.token !== null && user.token !== '' && redirectAuthUrl !== null && redirectAuthUrl.length > 0 && shouldLogout !== 'true') {
         window.location.href = redirectAuthUrl + '?token=' + user.token;
    }

    function logOut() {
        sessionStorage.setItem(Session.SESSION_STORAGE_API_TOKEN_KEY, '');

        window.location.href = '/' + authUrlParam;
    }

    function logOutAndRedirectBack() {
        sessionStorage.setItem(Session.SESSION_STORAGE_API_TOKEN_KEY, '');

        window.location.href = redirectAuthUrl;
    }

    let content;

    if (user.token !== null && user.token.length > 0) {
        content = (
            <PageContent>
                <Text as='kbd'>You are already logged in! If you did get here to authorize you should be redirected soon!</Text>
                <Center>
                    <Box m={'15px'}>
                        <Button onClick={logOut} colorScheme='green'>Log out</Button>
                    </Box>
                </Center>
            </PageContent>
        );
    } else {
        content = (<SignInFormComponent />);
    }

    return (
        <>
            <Header version={generalData.version} />
            <Divider />
            <Divider />
            <Container className={'content'}>
                {content}
                <Outlet />
            </Container>
            <Footer year={generalData.year}/>
        </>
    );
}