import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    SimpleGrid,
    Text,
    useToast
} from "@chakra-ui/react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserSignUpReducer from "../../../stores/reducers/user/UserSignUpReducer";
import UserActionCreator from "../../../stores/actions/user/UserActionCreator";
import Select from "react-select";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

export default function SignUpFormComponent(props) {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [projectId, setProjectId] = useState('');

    let projects = useSelector(state => state.project.elements);

    const [searchParams, setSearchParams] = useSearchParams();

    let redirectAuthUrl = searchParams.get('redirectAuthUrl');

    let authUrlParam = '';

    if (redirectAuthUrl !== null && redirectAuthUrl.length > 0) {
        authUrlParam = '?redirectAuthUrl=' + redirectAuthUrl;
    }

    function signUp() {
        if (!login || !email || !password || !repeatedPassword) {
            toast({
                title: 'Make sure you filled up all inputs!',
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "bottom"
            });
        } else if (password !== repeatedPassword) {
            toast({
                title: 'Your password does not match repeated password!',
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "bottom"
            });
        } else {
            dispatch(
                UserSignUpReducer.signUp(
                    UserActionCreator.signUp(
                        login,
                        email,
                        password,
                        repeatedPassword,
                        projectId
                    )
                )
            );

            navigate('/' + authUrlParam);
        }
    }

    function prepareOptions(projects) {
        let result = [];

        if (projects !== null && projects.length > 0) {
            Object.values(projects).forEach(project => {
                result.push({
                    value: project.project_id,
                    label: project.name
                });
            });
        }

        return result;
    }

    return (
        <>
            <Flex width={"99vw"} height={"85vh"} alignContent={"center"} justifyContent={"center"}>
                <Center>
                    <Box boxShadow='2xl' rounded='md' bg='white' className={'centredBox'}>
                        <Center>
                            <Heading as={'h1'}>
                                <Text>
                                    Sign Up
                                </Text>
                            </Heading>
                        </Center>
                        <form action="src/components/authentication/signin">
                            <SimpleGrid columns={{sm: 2, md: 2, lg: 2}}>
                                <FormControl className={'signUpFormControl'}>
                                    <FormLabel>Login</FormLabel>
                                    <Input onChange={(e) => setLogin(e.target.value)} name={'login'} />
                                </FormControl>
                                <FormControl className={'signUpFormControl'}>
                                    <FormLabel>E-mail</FormLabel>
                                    <Input onChange={(e) => setEmail(e.target.value)} type='email' name={'email'} />
                                </FormControl>
                                <FormControl className={'signUpFormControl'}>
                                    <FormLabel>Password</FormLabel>
                                    <Input onChange={(e) => setPassword(e.target.value)} type='password' name={'password'} />
                                </FormControl>
                                <FormControl className={'signUpFormControl'}>
                                    <FormLabel>Repeat Password</FormLabel>
                                    <Input onChange={(e) => setRepeatedPassword(e.target.value)} type='password' name={'repeatedPassword'} />
                                </FormControl>
                            </SimpleGrid>
                            <FormControl className={'signUpFormControl'}>
                                <FormLabel>Project</FormLabel>
                                <Select
                                    options={prepareOptions(projects)}
                                    onChange={e => setProjectId(e.value)}
                                />
                            </FormControl>

                            <Center>
                                <Box m={'20px'}>
                                    <Button onClick={signUp} colorScheme='green'>Sign up</Button>
                                </Box>
                            </Center>
                            <Center>
                                <Box>
                                    <Link className={'chakra-button'} to={'/' + authUrlParam}>
                                        Sign in
                                    </Link>
                                </Box>
                            </Center>
                        </form>
                    </Box>
                </Center>
            </Flex>
        </>
    );
}
