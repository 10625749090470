import {
    Badge,
    Box,
    Container,
    Flex,
    Heading,
    HStack,
    Spacer
} from "@chakra-ui/react";
import {Link} from "react-router-dom";

export default function Header(props) {
    const titleCss = {
        color: '#5400a9'
    };
    const version = props.version;

    return (
        <>
            <Container maxW='full' maxH={150}>
                <Flex minWidth='max-content' gap='2'>
                    <Box m={2}>
                        <HStack>
                            <Heading as='h3' size='lg' style={titleCss}>
                                <Link to=''>Organizer Authenticator</Link> <Badge colorScheme='purple'>v{version}</Badge>
                            </Heading>
                        </HStack>
                    </Box>
                    <Spacer />
                </Flex>
            </Container>
        </>
    );
}