import {Box, Button, Center, Flex, FormControl, FormLabel, Heading, Input, useToast} from "@chakra-ui/react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";
import {useDispatch} from "react-redux";
import UserSignInReducer from "../../../stores/reducers/user/UserSignInReducer";
import UserActionCreator from "../../../stores/actions/user/UserActionCreator";

export default function SignInFormComponent(props) {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    let redirectAuthUrl = searchParams.get('redirectAuthUrl');

    let authUrlParam = '';

    if (redirectAuthUrl !== null && redirectAuthUrl.length > 0) {
        authUrlParam = '?redirectAuthUrl=' + redirectAuthUrl;
    }

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    function signIn() {
        if (!login || !password) {
            toast({
                title: 'Login or password was not provided!',
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "bottom"
            });
        } else if (redirectAuthUrl == null || redirectAuthUrl.length <= 0) {
            toast({
                title: 'There is not link to be redirect on! Are you sure you got here properly?',
                status: "error",
                isClosable: true,
                duration: 5000,
                position: "bottom"
            });
        }
        else {
            dispatch(UserSignInReducer.signIn(
                UserActionCreator.signIn(login, password)
            ));

            navigate('/' + authUrlParam);
        }
    }

    return (
        <>
            <Flex width={"99vw"} height={"85vh"} alignContent={"center"} justifyContent={"center"}>
                <Center>
                    <Box boxShadow='2xl' rounded='md' bg='white' className={'centredBox'}>
                        <Center>
                            <Heading as={'h1'}>
                                Sign In
                            </Heading>
                        </Center>
                        <form action="/">
                            <FormControl className={'signInFormControl'}>
                                <FormLabel>Login</FormLabel>
                                <Input value={login} onChange={(e) => setLogin(e.target.value)} name={'login'} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input value={password} onChange={(e) => setPassword(e.target.value)} type='password' name={'password'} />
                            </FormControl>

                            <Center>
                                <Box m={'15px'}>
                                    <Button onClick={signIn} colorScheme='green'>Sign in</Button>
                                </Box>
                            </Center>

                            <Center>
                                <Box>
                                    <Link className={'chakra-button'} to={'/signup' + authUrlParam}>
                                        Sign up
                                    </Link>
                                </Box>
                            </Center>
                        </form>
                    </Box>
                </Center>
            </Flex>
        </>
    );
}