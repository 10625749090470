import Types from "../../../utils/dictionaries/action/Types";
import FetchProjectsReducer from "./FetchProjectsReducer";

export default function (state = [], action) {
    switch (action.type) {
        case Types.PROJECT.ALL.FETCH.FETCH:
            return FetchProjectsReducer.fetchAll(action);
        case Types.PROJECT.ALL.FETCH.FINISHED:
            return FetchProjectsReducer.fetchAllFinished(state, action);
        default:
            break;
    }

    return state;
}
