import Types from "../../../utils/dictionaries/action/Types";

function fetchProjects() {
    return {
        type: Types.PROJECT.ALL.FETCH.FETCH
    };
}

function fetchProjectsFinished(projects) {
    return {
        type: Types.PROJECT.ALL.FETCH.FINISHED,
        payload: {
            projects: projects
        }
    };
}

function fetchProjectError(error) {
    return {
        type: Types.PROJECT.ALL.FETCH.ERROR,
        error: 1,
        payload: error
    };
}

export default {
    fetchProjects,
    fetchProjectsFinished,
    fetchProjectError
}
