
import {Container, Divider} from "@chakra-ui/react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SignInFormComponent from "../../components/user/signin/SignInFormComponent";

export default function SignInLayout(props) {
    const generalData = props.generalData;

    return (
        <>
            <Header version={generalData.version} header={'Organizer Auth'} />
            <Divider />
            <Container className={'content'}>
                <SignInFormComponent />
            </Container>
            <Footer year={generalData.year}/>
        </>
    );
}
