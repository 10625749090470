import {Container, Divider} from "@chakra-ui/react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SignUpFormComponent from "../../components/user/signup/SignUpFormComponent";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import FetchProjectsReducer from "../../stores/reducers/project/FetchProjectsReducer";
import ProjectActionCreator from "../../stores/actions/project/ProjectActionCreator";

export default function SignUpLayout(props) {
    const dispatch = useDispatch();

    const generalData = props.generalData;

    useEffect(() => {
        dispatch(
            FetchProjectsReducer.fetchAll(
                ProjectActionCreator.fetchProjects()
            )
        );
    });

    return (
        <>
            <Header version={generalData.version} header={'Organizer Auth'} />
            <Divider />
            <Container className={'content'}>
                <SignUpFormComponent logInFunction={props.logInFunction} />
            </Container>
            <Footer year={generalData.year}/>
        </>
    );
}