import organizerAuth from "../../utils/dictionaries/routes/api/organizer-auth";
import OrganizerAuthApiClient from "../../utils/OrganizerAuthApiClient";

export default function SignUp(login, email, password, projectId) {
    const api = organizerAuth();

    return OrganizerAuthApiClient.post(api.signup, {
        login,
        email,
        password,
        project_id: projectId
    });
}
