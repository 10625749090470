import {useRoutes} from "react-router";
import IndexLayout from "./layouts/IndexLayout";
import {useSelector} from "react-redux";
import SignUpLayout from "./layouts/user/SignUpLayout";
import SignInLayout from "./layouts/user/SignInLayout";


export default function Router() {

    const version = useSelector((state) => state.data.general.version);
    const year = useSelector((state) => state.data.general.year);

    const generalData = {
        version,
        year
    };

    return useRoutes([
        {
            path: '/',
            element: <IndexLayout generalData={generalData} />
        },
        {
            path: '/signup',
            element: <SignUpLayout generalData={generalData} />
        },
        {
            path: '/signin',
            element: <SignInLayout generalData={generalData} />
        }
    ]);
}
