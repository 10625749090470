import organizerAuth from "../../utils/dictionaries/routes/api/organizer-auth";
import OrganizerAuthApiClient from "../../utils/OrganizerAuthApiClient";

export default function SignIn(login, password) {
    const api = organizerAuth();

    return OrganizerAuthApiClient.post(api.signin, {
        login,
        password
    });
}