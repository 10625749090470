import SignIn from "../../../services/user/SignIn";
import UserActionCreator from "../../actions/user/UserActionCreator";
import Session from "../../../utils/dictionaries/session";

function signIn(action) {
    return async function signInThunk(dispatch, getState) {
        await SignIn(action.payload.login, action.payload.password)
            .then(response => {
                dispatch(
                    UserActionCreator.signInFinished(response.data.message._token)
                );
            })
            .catch(error => {
                console.log(error);
            })
    }
}

function signInFinished(state, action) {
    sessionStorage.setItem(Session.SESSION_STORAGE_API_TOKEN_KEY, action.payload.token);

    return {
        ...state,
        me: {
            ...state.me,
            token: action.payload.token
        }
    };
}

export default {
    signIn,
    signInFinished
};
