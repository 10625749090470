
const Types = {
    USER: {
        ME: {
            FETCH: {
                FINISHED: 'USER|ME_FETCH_FINISHED',
                ERROR: 'USER|ME_FETCH_ERROR',
                FETCH: 'USER|ME_FETCH',
            }
        },
        SIGNIN: {
            ERROR: 'USER|SIGNIN_ERROR',
            FINISHED: 'USER|SIGNIN_FINISHED',
            SIGNIN: 'USER|SIGNIN',
        },
        SIGNUP: {
            ERROR: 'USER|SIGNUP_ERROR',
            FINISHED: 'USER|SIGHUP_FINISHED',
            SIGNUP: 'USER|SIGNUP'
        }
    },
    PROJECT: {
        ALL: {
            FETCH: {
                FINISHED: 'PROJECT|FETCH_ALL_FINISHED',
                ERROR: 'PROJECT|FETCH_ALL_ERROR',
                FETCH: 'PROJECT|FETCH_ALL'
            }
        }
    }
};

export default Types;
