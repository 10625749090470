import {combineReducers} from "redux";
import DataReducer from "./DataReducer";
import UserReducer from "./user/UserReducer";
import ProjectReducer from "./project/ProjectReducer";


const rootReducer = combineReducers({
    data: DataReducer,
    user: UserReducer,
    project: ProjectReducer
});

export default rootReducer;
